import {
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonToggle,
  useIonAlert,
  useIonLoading,
  useIonViewWillEnter
} from '@ionic/react'

import Input from 'app/components/input/Input'
import LoginLayout from 'app/components/login-layout/LoginLayout'
import { useConfig } from 'app/context/config/ConfigContext'
import { useSession } from 'app/context/session/useSession'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { getClientConfig, getRegularUserPool } from 'services/auth'
import { getStoredKey, setStoredKey } from 'services/storage'
import { getTranslation } from 'translations'
import constants from '../../../constants'
import './Login.scss'

const Login: React.FC = () => {
  const { data: brandConfig } = useConfig()
  const [supportUrl, setSupportUrl] = useState(
    process.env.REACT_APP_DEFAULT_SUPPORT_LINK
  )
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    setValue,
    control
  } = useForm({ mode: 'all', defaultValues: { username: '', password: '' } })

  const [loggingIn, setLoggingIn] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)

  const { login, isAuthenticated, userLanguageCode } = useSession()
  const [present, dismiss] = useIonLoading()
  const [presentAlert] = useIonAlert()
  const history = useHistory()
  const [hasPool, setHasPool] = useState(true)

  const redirect = async () => {
    history.push('/explore')
  }

  const setSavedCredentials = async () => {
    const savedUsername = await getStoredKey(constants.STORED_USERNAME)
    const savedPassword = await getStoredKey(constants.STORED_PASSWORD)
    if (savedUsername && savedPassword) {
      setValue('username', savedUsername)
      setValue('password', savedPassword)
      setRememberMe(true)
    }
  }

  useEffect(() => {
    async function getData() {
      if (isAuthenticated) {
        redirect()
        return
      }
      if (brandConfig) {
        const clientConfig: any = await getClientConfig(
          brandConfig.brandDynamic.client
        )

        try {
          if (brandConfig.brandDynamic.config.flags.supportUrl) {
            setSupportUrl(brandConfig.brandDynamic.config.flags.supportUrl)
          }

          if (clientConfig.clientDynamic) {
            await setStoredKey(
              constants.CLIENT_NAME,
              clientConfig.clientDynamic.name
            )
          }

          const cognitoInfo: any = await getRegularUserPool()
          setHasPool(!!cognitoInfo)

          if (cognitoInfo) {
            await setStoredKey(
              constants.COGNITO_POOL_ID,
              cognitoInfo.userPoolId
            )
            await setStoredKey(
              constants.COGNITO_CLIENT_ID,
              cognitoInfo.clientId
            )
          }
        } catch {
          console.log('something was undefined')
        }

        try {
          if (
            brandConfig.brandDynamic.config.flags.embellishmentStrategies[0]
              .iframeUrl
          ) {
            await setStoredKey(
              constants.CALLAWAY_IFRAME_URL,
              brandConfig.brandDynamic.config.flags.embellishmentStrategies[0]
                .iframeUrl
            )
          }
        } catch {
          console.log('something was undefined')
        }
      }
    }

    async function checkQueryParams() {
      // force a hard refresh after logout
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params.private != null) {
        const win: Window = window
        win.location = window.location.pathname
      }

      let currentClient = ''
      const dnsSplit = window.location.hostname.split('-')
      if (dnsSplit.length > 1 && dnsSplit[0].indexOf('pwa') === -1) {
        currentClient = dnsSplit[0]
        currentClient = currentClient.replace('www.', '').replace('www', '')
        await setStoredKey(constants.CURRENT_CLIENT_NAME, currentClient)
      } else {
        currentClient = await getStoredKey(constants.CURRENT_CLIENT_NAME)
      }

      if (params.client != null) {
        await setStoredKey(constants.CURRENT_CLIENT_NAME, params.client)
      } else if (!currentClient && currentClient != '') {
        await setStoredKey(
          constants.CURRENT_CLIENT_NAME,
          constants.DEFAULT_CLIENT_NAME
        )
      }

      await getData()
    }

    if (brandConfig) {
      setSavedCredentials()
      checkQueryParams()
    }
    // eslint-disable-next-line
  }, [history, isAuthenticated, brandConfig])

  // safari sets these back if you've logged in before
  useIonViewWillEnter(() => {
    setValue('username', '')
    setValue('password', '')
  })

  const closeModals = () => {
    var modals = document.getElementsByTagName('ion-modal')
    ;[].forEach.call(modals, function (el: any) {
      el.setAttribute('is-open', 'false')
    })
  }

  const handleLogin = async ({
    username,
    password
  }: {
    username: string
    password: string
  }) => {
    await present({ message: 'Logging in...' })
    setLoggingIn(true)
    const attempt = await login(username, password, rememberMe)
    await dismiss()
    if (!attempt) {
      setLoggingIn(false)
      presentAlert({
        header: 'Alert',
        message: 'Wrong username or password.',
        buttons: [
          {
            text: 'OK',
            role: 'confirm'
          }
        ]
      })
    } else {
      setLoggingIn(false)
      closeModals()
      if (rememberMe) {
        await setStoredKey(constants.STORED_PASSWORD, password)
        await setStoredKey(constants.STORED_USERNAME, username)
      }
      redirect()
    }
  }

  return (
    <IonPage className="login-page">
      <IonContent className="main-content">
        <LoginLayout>
          <form
            className="ion-padding"
            target="_top"
            onSubmit={handleSubmit(handleLogin)}
          >
            <h1>{getTranslation('login', userLanguageCode)}</h1>
            <Input
              label={getTranslation('username', userLanguageCode)}
              type="text"
              {...register('username', { required: true })}
              control={control}
            />
            <Input
              label={getTranslation('password', userLanguageCode)}
              type="password"
              {...register('password', { required: true })}
              control={control}
            />

            <IonItem lines="none">
              <div className="toggle-container">
                <IonLabel className="toggle-label">
                  {getTranslation('rememberMe', userLanguageCode)}{' '}
                </IonLabel>
                <IonToggle
                  checked={rememberMe}
                  onIonChange={(e) => setRememberMe(e.detail.checked)}
                  aria-label={getTranslation('rememberMe', userLanguageCode)}
                />
              </div>
            </IonItem>

            <IonButton
              className="ion-margin-top"
              type="submit"
              expand="block"
              disabled={loggingIn || !isDirty || !isValid || !hasPool}
              border-radius="15px"
            >
              {getTranslation('login', userLanguageCode)}
            </IonButton>
          </form>
          {brandConfig &&
            !brandConfig.brandDynamic.config.flags.hideForgotPassword && (
              <div className="support-link">
                <IonRouterLink
                  routerLink="/forgot-password"
                  onClick={() => closeModals()}
                >
                  {getTranslation('forgotUsernamePassowrd', userLanguageCode)}
                </IonRouterLink>
              </div>
            )}
          {brandConfig &&
            !brandConfig.brandDynamic.config.flags.hideElasticSupportLink && (
              <div className="support-link">
                {getTranslation('needHelp', userLanguageCode)}{' '}
                <a href={supportUrl} rel="noreferrer" target={'_blank'}>
                  {getTranslation('getSupport', userLanguageCode)}
                </a>
              </div>
            )}
        </LoginLayout>
      </IonContent>
    </IonPage>
  )
}

export default Login

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar
} from '@ionic/react'
import cn from 'classnames'
import { closeOutline } from 'ionicons/icons'
import { FC, ReactElement, ReactNode } from 'react'
import './PWAModal.scss'

type ModalSizes = 'small' | 'big' | 'full-screen'

export type PWAModalProps = {
  isOpen: boolean
  toggle: () => void
  header?: ReactElement
  footer?: ReactElement
  size?: ModalSizes
  children?: ReactNode
  className?: string
  initialBreakpoint?: number
}

const PWAModal: FC<PWAModalProps> = ({
  isOpen,
  toggle,
  header,
  footer,
  size = 'small',
  children,
  className,
  ...rest
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      className={cn('pwa-modal-layout', `pwa-modal-layout--${size}`, className)}
      role="dialog"
      {...rest}
    >
      {header && (
        <IonHeader className="no-border-toolbar">
          <div className="padding-control">
            <IonToolbar mode="ios" className="header">
              {header}
              <IonButtons slot="end">
                <IonButton data-testid="Close modal" onClick={toggle}>
                  <IonIcon
                    className="icon"
                    size="large"
                    icon={closeOutline}
                    color="primary"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </div>
        </IonHeader>
      )}
      <IonContent className="ion-padding">{children}</IonContent>
      {footer && (
        <IonFooter className="ion-padding footer ion-no-border">
          {footer}
        </IonFooter>
      )}
    </IonModal>
  )
}

export default PWAModal
